import {
    Accordion,
    Carousel,
    Container,
} from "@peracto/peracto-blocks-tailwind";

import Products from "./KooltechProducts";

export default {
    plugins: {
        content: [Carousel, Accordion, Products],
        layout: [Container],
    },
};
