import React, { useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleLeft } from "@fortawesome/pro-regular-svg-icons/faArrowCircleLeft";
import { faRedoAlt } from "@fortawesome/pro-regular-svg-icons/faRedoAlt";
import { faEnvelopeOpen } from "@fortawesome/pro-regular-svg-icons/faEnvelopeOpen";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";

import dayjs from "dayjs";

import {
    Heading,
    Group,
    FormActions,
    NotFoundMessage,
    UnauthorisedMessage,
} from "@peracto/peracto-ui";
import { useConfig } from "@peracto/peracto-config";

import { GET_ONE, CREATE, UPDATE, useClient } from "@peracto/client";
import * as S from "../styled";
import isEmpty from "lodash/isEmpty";
import startCase from "lodash/startCase";

import OrderHistory from "./OrderHistory";

const currencySymbols = {
    GBP: "£",
    USD: "$",
    EUR: "€",
};

export const formatAsCurrency = (price = 0, currency = "GBP") => {
    return `${currencySymbols[currency]}${(price / 100).toFixed(2)}`;
};

const OrdersViewContainer = ({
    orderTransferred,
    orderConfirmationEmailCanBeResent,
    onResendConfirmationEmail,
    onResetOrder,
    children,
}) => {
    const config = useConfig();
    const { orders } = config.get("features", {});

    return (
        <div className="form-container">
            <Heading name="View Order">
                <div className="flex-grow-1 d-flex align-items-center justify-content-end">
                    {orderTransferred && orders?.resendOrders && (
                        <button
                            onClick={onResetOrder}
                            className="btn btn-link"
                            data-testid="orders-resend-order"
                        >
                            <FontAwesomeIcon
                                icon={faRedoAlt}
                                className="mr-2"
                            />
                            Resend Order
                        </button>
                    )}

                    {orders?.resendConfirmationEmails && (
                        <div
                            className="tooltip-wrap tooltip-below"
                            {...(!orderConfirmationEmailCanBeResent && {
                                "data-tooltip":
                                    "This order is not eligible for a confirmation email to be sent out",
                            })}
                        >
                            <button
                                onClick={onResendConfirmationEmail}
                                className="btn btn-link"
                                data-testid="orders-resend-confirmation-email"
                                {...(!orderConfirmationEmailCanBeResent && {
                                    style: {
                                        opacity: 0.3,
                                        cursor: "not-allowed",
                                    },
                                })}
                            >
                                <FontAwesomeIcon
                                    icon={faEnvelopeOpen}
                                    className="mr-2"
                                />
                                Resend Confimation Email
                            </button>
                        </div>
                    )}

                    <Link className="btn btn-outline-primary" to="/orders">
                        <FontAwesomeIcon
                            icon={faArrowCircleLeft}
                            className="mr-2"
                        />
                        Back to Orders
                    </Link>
                </div>
            </Heading>
            {children}
        </div>
    );
};

const OrderAddress = ({ address, title = "" }) => (
    <S.OrderAddress>
        <p className="address-title">{title}</p>
        {(address?.forename || address?.surname) && (
            <p>
                {address?.forename} {address?.surname}
            </p>
        )}
        {address?.line1 && <p>{address?.line1}</p>}
        {address?.line2 && <p>{address?.line2}</p>}
        {address?.line3 && <p>{address?.line3}</p>}
        {address?.line4 && <p>{address?.line4}</p>}
        {address?.postalCode && <p>{address?.postalCode}</p>}
        {address?.county && <p>{address?.county}</p>}
        {address?.country && <p>{address?.country}</p>}
        {address?.telephone && <p>{address?.telephone}</p>}
    </S.OrderAddress>
);

const OrdersView = ({ location: { pathname } }) => {
    const { client } = useClient();
    const [loading, setLoading] = useState(true);
    const [unauthorised, setUnauthorised] = useState(false);
    const [notFound, setNotFound] = useState(false);
    const [redirect, setRedirect] = useState();
    const [orderData, setOrderData] = useState();

    const config = useConfig();
    const { orders } = config.get("features", {});

    // show prices as tax feature flag - default: true
    const includeTax =
        orders && orders.hasOwnProperty("includeTax")
            ? orders.includeTax === true
            : true;

    const fetchOrder = async () => {
        try {
            const { data, response } = await client(GET_ONE, "orders", {
                id: pathname,
            });

            if (response.status === 404) {
                setRedirect("/orders");
                setLoading(false);
                return;
            }

            setOrderData(data);
            setLoading(false);
        } catch (e) {
            console.error(e);

            if (e.status === 403) {
                setUnauthorised(true);
            }

            if (e.status === 404) {
                setNotFound(true);
            }

            setRedirect("/orders");
            setLoading(false);
        }
    };

    const onResetTransferredStatus = async () => {
        try {
            await client(UPDATE, "order", {
                id: orderData["@id"],
                data: {
                    transferred: null,
                },
            });

            fetchOrder();
            toast.success("Order successfully resent!");
        } catch (e) {
            console.error(e);
            toast.error(
                e?.error?.body?.hasOwnProperty("hydra:description")
                    ? e.error.body["hydra:description"]
                    : "Whoops, there was a problem..."
            );
        }
    };

    const onResendOrderConfirmationEmail = async () => {
        if (!orderData?.orderConfirmationEmailCanBeResent) {
            return false;
        }
        try {
            await client(CREATE, "orders/resend-order-confirmation-email", {
                data: {
                    orderId: orderData.originId,
                },
            });

            toast.success("Order email successfully resent!");
        } catch (e) {
            console.error(e);
            toast.error(
                e?.error?.body?.hasOwnProperty("hydra:description")
                    ? e.error.body["hydra:description"]
                    : "Whoops, there was a problem..."
            );
        }
    };

    const onSubmitForm = async (values, actions) => {
        const headers = {};

        const { userEmailSent, ...restValues } = values;

        if (userEmailSent) {
            headers["peracto-send-user-order-history-email"] = true;
        }

        try {
            const response = await client(
                CREATE,
                "order-histories",
                {
                    data: {
                        order: pathname,
                        ...restValues,
                    },
                },
                headers
            );

            if (
                response.data.violations &&
                response.data.violations.length > 0
            ) {
                // Display errors for invalid fields
                actions.setSubmitting(false);
                response.data.violations.forEach((error) => {
                    actions.setFieldError(error.propertyPath, error.message);
                });
            } else {
                actions.setSubmitting(false);
                actions.resetForm();
                fetchOrder();
                toast.success("Note successfully added!");
            }
        } catch (e) {
            console.error(e);
            actions.resetForm();
            toast.error(
                e?.error?.body?.hasOwnProperty("hydra:description")
                    ? e.error.body["hydra:description"]
                    : "Whoops, there was a problem..."
            );
            if (e?.error?.body?.violations?.length > 0) {
                // Display errors for invalid fields
                actions.setSubmitting(false);
                e.error.body.violations.forEach((error) => {
                    actions.setFieldError(error.propertyPath, error.message);
                });
            }
            actions.setSubmitting(false);
        }
    };

    useEffect(() => {
        fetchOrder();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (loading) {
        return (
            <OrdersViewContainer
                orderTransferred={null}
                onResetOrder={() => onResetTransferredStatus()}
            >
                <div className="card">
                    <div className="card-body">Loading...</div>
                </div>
            </OrdersViewContainer>
        );
    }

    if (unauthorised) {
        return <UnauthorisedMessage />;
    }

    if (notFound) {
        return (
            <NotFoundMessage
                url="/orders"
                message="The order you're looking for could not be found"
                buttonLabel="Go to Orders"
            />
        );
    }

    return (
        <OrdersViewContainer
            orderTransferred={!!orderData?.transferred}
            orderConfirmationEmailCanBeResent={
                orderData?.orderConfirmationEmailCanBeResent
            }
            onResendConfirmationEmail={onResendOrderConfirmationEmail}
            onResetOrder={() => onResetTransferredStatus()}
        >
            {redirect ? (
                <Redirect to={redirect} />
            ) : (
                <>
                    <Helmet>
                        <title>
                            {orderData.reference
                                ? `Order #: ${orderData.reference}`
                                : "Order"}{" "}
                            | View | Peracto
                        </title>
                    </Helmet>
                    {
                        <>
                            <FormActions>
                                <span className="mr-3">
                                    Order ID:{" "}
                                    <span className="font-weight-bold">
                                        {orderData.reference}
                                    </span>
                                </span>

                                <span className="mr-3">
                                    Customer Reference:{" "}
                                    <span className="font-weight-bold">
                                        {orderData.customerReference}
                                    </span>
                                </span>

                                <span className="mr-3">
                                    Status:{" "}
                                    <span className="font-weight-bold text-capitalize">
                                        {orderData.status}
                                    </span>
                                </span>

                                {orderData?.purchaseOrder && (
                                    <span className="mr-3">
                                        Purchase Order:{" "}
                                        <span className="font-weight-bold">
                                            {orderData.purchaseOrder}
                                        </span>
                                    </span>
                                )}

                                <span className="mr-3">
                                    Created:{" "}
                                    <span className="font-weight-bold text-capitalize">
                                        {dayjs(
                                            new Date(orderData.createdAt)
                                        ).format("YYYY-MM-DD HH:mm:ss")}
                                    </span>
                                </span>

                                {orderData?.kerridgeOrderNumber && (
                                    <span className="mr-3">
                                        Kerridge Order Number:{" "}
                                        <span className="font-weight-bold">
                                            {orderData.kerridgeOrderNumber}
                                        </span>
                                    </span>
                                )}
                                {orderData?.transferred && (
                                    <span className="mr-3">
                                        Transferred:{" "}
                                        <span className="font-weight-bold text-capitalize">
                                            {dayjs(
                                                new Date(orderData.transferred)
                                            ).format("YYYY-MM-DD HH:mm:ss")}
                                        </span>
                                    </span>
                                )}
                            </FormActions>
                            <Group key="items" id="items" name="Items">
                                <table className="table">
                                    <colgroup>
                                        <col width="10%" />
                                        <col width="45%" />
                                        <col width="15%" />
                                        <col width="15%" />
                                        <col width="15%" />
                                    </colgroup>

                                    <thead>
                                        <tr>
                                            <th className="border-top-0"></th>
                                            <th className="border-top-0"></th>
                                            <th className="border-top-0 text-right">
                                                Quantity
                                            </th>
                                            <th className="border-top-0 text-right">
                                                Total Discount
                                            </th>
                                            <th className="border-top-0 text-right">
                                                Line Total
                                            </th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {orderData?.lines?.map((line, idx) => (
                                            <tr key={`product_${idx}`}>
                                                <td>
                                                    <img
                                                        src={line.item?.image}
                                                        alt=""
                                                        className="w-100"
                                                    />
                                                </td>
                                                <td className="overflow-anywhere">
                                                    <p className="font-weight-bold mb-0">
                                                        {line.item?.name}
                                                    </p>
                                                    {line.item?.sku && (
                                                        <p className="mb-0">
                                                            {line.item?.sku}
                                                        </p>
                                                    )}
                                                    {!isEmpty(
                                                        line.item
                                                            ?.variantOptions
                                                    ) && (
                                                        <p className="mb-2">
                                                            {Object.values(
                                                                line.item
                                                                    .variantOptions
                                                            ).map((v) => (
                                                                <>
                                                                    <span className="font-weight-bold">
                                                                        {v.name}
                                                                        :
                                                                    </span>{" "}
                                                                    {
                                                                        v.displayValue
                                                                    }{" "}
                                                                </>
                                                            ))}
                                                        </p>
                                                    )}
                                                    {line.line && (
                                                        <p className="mb-0">
                                                            {formatAsCurrency(
                                                                line.priceExcTaxBeforeDiscount,
                                                                orderData?.currency
                                                            )}
                                                        </p>
                                                    )}
                                                </td>
                                                <td className="text-right">
                                                    {line.quantity}
                                                </td>
                                                <td className="text-right">
                                                    <p className="mb-0">
                                                        {line?.promotions
                                                            ? formatAsCurrency(
                                                                  line.promotions?.reduce(
                                                                      (
                                                                          acc,
                                                                          curVal
                                                                      ) => {
                                                                          return (
                                                                              acc +
                                                                              curVal
                                                                                  ?.discount
                                                                                  ?.excTax
                                                                          );
                                                                      },
                                                                      0
                                                                  ),
                                                                  orderData?.currency
                                                              )
                                                            : formatAsCurrency(
                                                                  0,
                                                                  orderData?.currency
                                                              )}
                                                    </p>
                                                </td>
                                                <td className="text-right">
                                                    {formatAsCurrency(
                                                        line?.linePriceTotal
                                                            ?.excTax,
                                                        orderData?.currency
                                                    )}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>

                                <hr />

                                <table className="table table-sm table-borderless">
                                    <colgroup>
                                        <col width="15%" />
                                        <col width="70%" />
                                        <col width="15%" />
                                    </colgroup>

                                    <tbody>
                                        <tr>
                                            <td className="font-weight-bold">
                                                Subtotal
                                            </td>
                                            <td>
                                                {orderData?.lines?.length} item
                                                {orderData?.lines?.length !== 1
                                                    ? "s"
                                                    : ""}
                                            </td>
                                            <td className="text-right">
                                                {formatAsCurrency(
                                                    orderData?.totalExcTaxBeforeDiscount,
                                                    orderData?.currency
                                                )}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>Shipping</td>
                                            <td>
                                                {orderData.shippingServiceName}
                                            </td>
                                            <td className="text-right">
                                                {formatAsCurrency(
                                                    orderData?.shippingTotalAfterDiscount ||
                                                        orderData?.shippingTotal ||
                                                        (includeTax
                                                            ? orderData?.shippingTotalAfterDiscountIncTax
                                                            : orderData?.shippingTotalAfterDiscountExcTax),
                                                    orderData?.currency
                                                )}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>Total Discount</td>
                                            <td></td>
                                            <td className="text-right">
                                                {formatAsCurrency(
                                                    orderData?.totalDiscount,
                                                    orderData?.currency
                                                )}
                                            </td>
                                        </tr>

                                        {includeTax && (
                                            <tr>
                                                <td>Tax</td>
                                                <td></td>
                                                <td className="text-right">
                                                    {formatAsCurrency(
                                                        orderData?.totalTaxAfterDiscount,
                                                        orderData?.currency
                                                    )}
                                                </td>
                                            </tr>
                                        )}

                                        <tr className="border-top">
                                            <td className="font-weight-bold">
                                                Paid by customer
                                            </td>
                                            <td></td>
                                            <td className="text-right font-weight-bold">
                                                {formatAsCurrency(
                                                    includeTax
                                                        ? orderData?.totalIncTaxAfterDiscount
                                                        : orderData?.totalExcTaxAfterDiscount,
                                                    orderData?.currency
                                                )}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Group>
                            <Group key="customer" id="customer" name="Customer">
                                <S.OrderAddress>
                                    {(orderData?.user?.firstName ||
                                        orderData?.user?.lastName) && (
                                        <p className="address-title">{`${orderData?.user?.firstName} ${orderData?.user?.lastName}`}</p>
                                    )}
                                    {(orderData?.email ||
                                        orderData?.telephone) && (
                                        <p>
                                            {orderData?.email
                                                ? orderData?.email
                                                : ""}
                                            {orderData?.email &&
                                                orderData?.telephone &&
                                                " / "}
                                            {orderData?.telephone
                                                ? orderData?.telephone
                                                : ""}
                                        </p>
                                    )}
                                    {orderData?.user?.defaultBranch?.name && (
                                        <p>{`${orderData?.user?.defaultBranch?.name}`}</p>
                                    )}
                                </S.OrderAddress>

                                {orderData?.deliveryAddress && (
                                    <>
                                        <hr />

                                        <OrderAddress
                                            address={orderData.deliveryAddress}
                                            title="Shipping Address"
                                        />
                                    </>
                                )}

                                {orderData?.deliveryAddress && (
                                    <>
                                        <hr />

                                        <OrderAddress
                                            address={orderData.billingAddress}
                                            title="Billing Address"
                                        />
                                    </>
                                )}
                            </Group>

                            {orders?.orderHistory && (
                                <Group
                                    key="history"
                                    id="history"
                                    name="History"
                                >
                                    <OrderHistory
                                        history={orderData.history}
                                        onSubmitForm={onSubmitForm}
                                    />
                                </Group>
                            )}
                            <Group key="shipping" id="shipping" name="Shipping">
                                <table className="table table-sm table-borderless">
                                    <colgroup>
                                        <col width="20%" />
                                        <col width="80%" />
                                    </colgroup>
                                    <tbody>
                                        <tr>
                                            <td className="font-weight-bold">
                                                Service:
                                            </td>
                                            <td>
                                                {orderData?.shippingServiceName}
                                            </td>
                                        </tr>

                                        {orderData.shippingDate && (
                                            <tr>
                                                <td className="font-weight-bold">
                                                    Shipping Date:
                                                </td>
                                                <td>
                                                    {dayjs(
                                                        new Date(
                                                            orderData.shippingDate
                                                        )
                                                    ).format(
                                                        "YYYY-MM-DD HH:mm:ss"
                                                    )}
                                                </td>
                                            </tr>
                                        )}

                                        <tr>
                                            <td className="font-weight-bold">
                                                Delivery Notes:
                                            </td>
                                            <td>
                                                {orderData?.deliveryNotes ? (
                                                    orderData?.deliveryNotes
                                                ) : (
                                                    <span className="text-italic">
                                                        n/a
                                                    </span>
                                                )}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Group>

                            <Group
                                key="transactions"
                                id="transactions"
                                name="Transactions"
                            >
                                {orderData?.paymentTransactions?.length > 0 ? (
                                    orderData?.paymentTransactions?.map(
                                        (transaction, idx) => (
                                            <>
                                                {idx > 0 && <hr />}
                                                <table
                                                    key={transaction["@id"]}
                                                    className="table table-sm table-borderless"
                                                >
                                                    <colgroup>
                                                        <col width="20%" />
                                                        <col width="80%" />
                                                    </colgroup>
                                                    <tbody>
                                                        <tr>
                                                            <td className="font-weight-bold">
                                                                Provider:
                                                            </td>
                                                            <td>
                                                                {transaction?.provider ||
                                                                    "-"}
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td className="font-weight-bold">
                                                                Status:
                                                            </td>
                                                            <td>
                                                                {transaction?.status
                                                                    ? startCase(
                                                                          transaction.status.replace(
                                                                              /_/g,
                                                                              " "
                                                                          )
                                                                      )
                                                                    : "-"}
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td className="font-weight-bold">
                                                                Status Detail:
                                                            </td>
                                                            <td>
                                                                {transaction?.statusDetail ||
                                                                    "-"}
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td className="font-weight-bold">
                                                                Transaction
                                                                Amount:
                                                            </td>
                                                            <td>
                                                                {formatAsCurrency(
                                                                    transaction?.transactionAmount,
                                                                    transaction?.currency
                                                                )}
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td className="font-weight-bold">
                                                                Payment Method:
                                                            </td>
                                                            <td>
                                                                {transaction?.paymentMethod ||
                                                                    "-"}
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td className="font-weight-bold">
                                                                Payment Auth
                                                                Number:
                                                            </td>
                                                            <td>
                                                                {transaction?.paymentAuthNumber ||
                                                                    "-"}
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td className="font-weight-bold">
                                                                Payment Gateway
                                                                Transaction ID:
                                                            </td>
                                                            <td>
                                                                {transaction?.paymentGatewayTransactionId ||
                                                                    "-"}
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td className="font-weight-bold">
                                                                Payment Provider
                                                                Security Key:
                                                            </td>
                                                            <td>
                                                                {transaction?.paymentProviderSecurityKey ||
                                                                    "-"}
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td className="font-weight-bold">
                                                                Payment Gateway
                                                                Data:
                                                            </td>
                                                            <td>
                                                                {transaction?.paymentGatewayData
                                                                    ? JSON.stringify(
                                                                          transaction.paymentGatewayData
                                                                      )
                                                                    : "-"}
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td className="font-weight-bold">
                                                                Date Created:
                                                            </td>
                                                            <td>
                                                                {dayjs(
                                                                    new Date(
                                                                        transaction.createdAt
                                                                    )
                                                                ).format(
                                                                    "YYYY-MM-DD HH:mm:ss"
                                                                )}
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td className="font-weight-bold">
                                                                Date Modified:
                                                            </td>
                                                            <td>
                                                                {dayjs(
                                                                    new Date(
                                                                        transaction.modifiedAt
                                                                    )
                                                                ).format(
                                                                    "YYYY-MM-DD HH:mm:ss"
                                                                )}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </>
                                        )
                                    )
                                ) : (
                                    <p className="py-4 text-center m-0">
                                        No Transactions
                                    </p>
                                )}
                            </Group>
                        </>
                    }
                </>
            )}
        </OrdersViewContainer>
    );
};

export default OrdersView;
