import { theme } from "@chakra-ui/core";
import { css } from "@emotion/core";

// [container, breakpoint]
const sizes = {
    sm: ["100%", "576px"],
    md: ["100%", "768px"],
    lg: ["100%px", "992px"],
    xl: ["1210px", "1210px"],
};

const breakpoints = Object.values(sizes).reduce((bp, size) => {
    bp.push(size[1]);
    return bp;
}, []);

breakpoints.sm = breakpoints[0];
breakpoints.md = breakpoints[1];
breakpoints.lg = breakpoints[2];
breakpoints.xl = breakpoints[3];

export const EditorTheme = {
    ...theme,
    breakpoints,
    colors: {
        ...theme.colors,
        brand: {
            primary: "#CD1719",
            secondary: "#283583",
            tertiary: "#76B82A",
            success: "#2DB200",
            warning: "#F49C10",
            danger: "#E30613",
            info: "#0697E3",
            white: "#FFFFFF",
            lightGrey: "#F4F4F4",
            grey: "#E6E6E6",
            darkGrey: "#505050",
            black: "#303030",
        },
    },
    fonts: {
        ...theme.fonts,
        heading: "Montserrat, sans-serif",
        body: "Montserrat, sans-serif",
    },
};

export const AdminTheme = {
    breakpoints: {
        mobileS: 320,
        mobileM: 375,
        mobileL: 425,
        tablet: 768,
        laptop: 992,
        desktop: 1024,
        desktopL: 1700,
    },
};

// Global styles designs: link to the client's style guide if there is one.

const listStyles = css`
    ul {
        list-style: none;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 20px;
        position: relative;

        li {
            line-height: 30px;
            padding-left: 20px;
            position: relative;

            &:before {
                display: block;
                content: "";
                position: absolute;
                left: 0;
                top: 10px;
                height: 8px;
                width: 8px;
                border-radius: 100%;
                margin-right: 10px;
                background-color: ${EditorTheme.colors.brand.black};
            }
        }
    }
`;

const headings = css`
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        text-transform: uppercase;
    }

    h1 {
        font-weight: 600;
        font-size: 28px;
        line-height: 32px;
    }

    h2 {
        font-weight: 600;
        font-size: 24px;
        line-height: 28px;
    }

    h3 {
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
    }

    h4 {
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
    }

    h5 {
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
    }

    h6 {
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
    }

    p {
        font-size: 14px;
        line-height: 20px;
    }

    hr {
        background: ${EditorTheme.colors.brand.grey};
        height: 2px;
        margin: 15px 0;
    }
`;

const inputs = css`
    input {
        border-color: ${EditorTheme.colors.brand.grey};
        border-radius: 0px;
        border-width: 1px;
        font-size: 12px;

        &:hover {
            background: ${EditorTheme.colors.brand.white} !important;
        }
        &:focus {
            outline: none;
            border-color: ${EditorTheme.colors.brand.secondary};
        }
    }

    select {
        // transition: border-color 0.2s;
        border-color: ${EditorTheme.colors.brand.grey};
        border-width: 1px !important;
        border-radius: 0px;
        height: 45px;

        &:hover {
            border-color: ${EditorTheme.colors.brand.grey};
        }
        &:focus {
            outline: none;
            border-color: ${EditorTheme.colors.brand.grey};
        }

        + div {
            svg {
                color: ${EditorTheme.colors.brand.secondary};
            }
        }
    }

    input[type="checkbox"] {
        + div {
            border-color: ${EditorTheme.colors.brand.lightGrey} !important;
            border: 1px solid;

            &:hover {
                border-color: ${EditorTheme.colors.brand.darkGrey} !important;
            }
        }

        &:checked {
            + div {
                background-color: ${EditorTheme.colors.brand.secondary};
                border-color: ${EditorTheme.colors.brand.secondary};
            }
        }
    }

    input[type="radio"] {
        + div {
            border-color: ${EditorTheme.colors.brand.lightGrey} !important;
            border-width: 1px !important;
        }

        &:hover {
            + div {
                border-color: ${EditorTheme.colors.brand.secondary} !important;
            }
        }
        &:checked {
            + div {
                background-color: ${EditorTheme.colors.brand
                    .secondary} !important;
                border-color: ${EditorTheme.colors.brand.lightGrey} !important;
                box-shadow: inset 0px 0px 0px 2px
                    ${EditorTheme.colors.brand.white} !important;

                span {
                    display: none;
                }
            }
        }
    }

    textarea {
        border-color: ${EditorTheme.colors.brand.lightGrey};
        border-width: 1px !important;
        border-radius: 0px !important;
        font-size: 12px;

        &:hover {
            background: ${EditorTheme.colors.brand.white} !important;
            border-color: ${EditorTheme.colors.brand.lightGrey};
            border-radius: 0px !important;
        }
        &:focus {
            border-color: ${EditorTheme.colors.brand.secondary};
            border-radius: 0px !important;
        }
    }
`;

const reactPage = css`
    .ory-row {
        color: ${EditorTheme.colors.brand.darkGrey} !important;
        -webkit-font-smoothing: antialiased;
        text-size-adjust: 100%;
        text-rendering: optimizelegibility;

        input {
            border-color: ${EditorTheme.colors.brand.grey} !important;
            border-radius: 0px;
            border-width: 1px;

            &:hover {
                border-color: ${EditorTheme.colors.brand.grey} !important;
            }
            &:focus {
                outline: none;
                border-color: ${EditorTheme.colors.brand.secondary} !important;
            }
        }

        .content__text,
        [data-testid="text-component"] {
            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
                margin-bottom: 10px;
            }

            p {
                margin-bottom: 1rem;

                &:last-of-type {
                    margin-bottom: 0;
                }
            }
        }

        blockquote {
            font-style: normal;
            border-left: 5px solid;
            border-color: ${EditorTheme.colors.brand.secondary} !important;
            padding-left: 24px;
            padding-right: 24px;

            p {
                font-size: 16px;
                line-height: 24px;
            }
        }

        .table {
            width: 100%;
        }

        table {
            width: 100%;
            font-size: 14px;
            line-height: 20px;

            border: 0px !important;
            th {
                border: 0px !important;
            }

            tr:first-of-type td {
                border: 0px;
                background-color: ${EditorTheme.colors.brand
                    .secondary} !important;
                color: ${EditorTheme.colors.brand.white} !important;
                font-weight: 600;
            }

            tr:nth-of-type(even) {
                border: 0px;
                background-color: ${EditorTheme.colors.brand.white} !important;
            }

            tr:nth-of-type(odd) {
                border: 0px;
                background-color: ${EditorTheme.colors.brand
                    .lightGrey} !important;
            }

            td {
                border: 0px !important;
                padding: 0.75rem;
                vertical-align: top;
            }
        }
    }

    textarea {
        border-color: ${EditorTheme.colors.brand.grey} !important;
        border-width: 1px !important;
        border-radius: 0px !important;

        &:hover {
            border-color: ${EditorTheme.colors.brand.grey} !important;
            border-radius: 0px !important;
        }
        &:focus {
            outline: none;
            border-color: ${EditorTheme.colors.brand.secondary} !important;
            border-radius: 0px !important;
        }
    }

    input[type="checkbox"] {
        + span {
            border: 1px solid ${EditorTheme.colors.brand.lightGrey} !important;
            border-radius: 0px !important;

            &:hover {
                border-color: ${EditorTheme.colors.brand.secondary} !important;
            }
        }

        &:checked {
            + div {
                background-color: ${EditorTheme.colors.brand.secondary};
                border-color: ${EditorTheme.colors.brand.secondary};
            }
        }
    }

    input[type="radio"] {
        border-color: ${EditorTheme.colors.brand.lightGrey} !important;
        border-width: 1px !important;
        border-radius: 10px !important;

        &:hover {
            border-color: ${EditorTheme.colors.brand.secondary} !important;
        }
        &:checked {
            background-color: ${EditorTheme.colors.brand.secondary} !important;
            border-color: ${EditorTheme.colors.brand.lightGrey} !important;
            box-shadow: inset 0px 0px 0px 2px ${EditorTheme.colors.brand.white} !important;

            span {
                display: none;
            }
        }
    }

    ul:not([class^="DayPicker"]) {
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 20px;
        position: relative;
        font-size: 14px;

        li {
            line-height: 30px;
            padding-left: 20px;
            position: relative;
            margin-bottom: 15px;

            &:before {
                content: "";
                position: absolute;
                border-radius: 0px;
                left: 0;
                top: 8px;
                height: 8px;
                width: 8px;
                margin-right: 10px;
                background-color: #009fe3;
            }
        }
    }

    ol {
        list-style: decimal;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 20px;
        position: relative;
        font-size: 14px;

        li {
            line-height: 30px;
            padding-left: 20px;
            position: relative;
            margin-left: 20px;
            margin-bottom: 15px;
        }
    }

    a:not([type="button"]) {
        color: ${EditorTheme.colors.brand.secondary} !important;
        text-decoration: underline;
        font-weight: 500;

        &:hover {
            color: ${EditorTheme.colors.brand.secondary} !important;
        }
        &:visited {
            color: #3f05c2;
        }
    }

    blockquote {
        font-style: normal;
        border-left: 5px solid;
        border-color: ${EditorTheme.colors.brand.secondary} !important;
        padding-left: 24px;
        padding-right: 24px;

        p {
            font-size: 16px;
            line-height: 24px;
        }
    }

    .table {
        width: 100%;
    }

    table {
        width: 100%;
        font-size: 14px;
        line-height: 20px;

        border: 0px !important;
        th {
            border: 0px !important;
        }

        tr:first-of-type td {
            border: 0px;
            background-color: ${EditorTheme.colors.brand.secondary} !important;
            color: theme("colors.brand-white");
            font-weight: 600;
        }

        tr:nth-of-type(even) {
            border: 0px;
            background-color: ${EditorTheme.colors.brand.white} !important;
        }

        tr:nth-of-type(odd) {
            border: 0px;
            background-color: ${EditorTheme.colors.brand.lightGrey} !important;
        }

        td {
            border: 0px !important;
            padding: 0.75rem;
            vertical-align: top;
        }
    }
`;

export const GlobalStyles = css`
    ${listStyles}
    ${headings}
  ${inputs}
  ${reactPage}
`;
