/* eslint-disable array-callback-return */
import toPairs from "lodash/toPairs";
import startCase from "lodash/startCase";
import isArray from "lodash/isArray";
import isNaN from "lodash/isNaN";
import isEmpty from "lodash/isEmpty";

export const IMPORT_TYPES = [
    { label: "Products", value: "product" },
    { label: "Product Attribute Values", value: "product_attribute" },
    { label: "Product Resources", value: "product_resource" },
    { label: "Product Inventory", value: "product_inventory" },
    { label: "Product Relationships", value: "product_relation" },
    { label: "Resources", value: "resource" },
];

export const PRODUCT_VARIANT_IMPORT_TYPES = [
    { label: "Product Options", value: "product_option" },
    { label: "Product Variants", value: "product_variant" },
    { label: "Product Variant Options", value: "product_variant_option" },
    { label: "Product Variant Inventory", value: "product_variant_inventory" },
];

export const DEFAULT_PRODUCT_STATUS_VALUES = [
    { label: "Active", value: "active" },
    { label: "Draft", value: "draft" },
    { label: "Deleted", value: "deleted" },
];

export const DEFAULT_PRODUCT_AVAILABILITY_VALUES = [
    { label: "Purchasable", value: "purchasable" },
    { label: "Enquiry Only", value: "enquiry_only" },
    { label: "Discontinued", value: "discontinued" },
    { label: "Withdrawn", value: "withdrawn" },
];

export const DEFAULT_PRICE_TYPES = [
    "rrp",
    "price",
    "salePrice",
    "clearancePrice",
];

export const DEFAULT_DISPLAY_TYPES = [
    "search_categories",
    "product_feed",
    "sitemaps",
    "content",
    "related_product",
];

export const EMPTY_CELL_OPTIONS = [
    { label: "Ignore Empty Cells", value: "ignoreEmptyCells" },
    { label: "Delete Existing Values", value: "removeExistingValues" },
];

export const DISPLAY_CHECKBOX_FIELDS = [
    "content",
    "product_feed",
    "related_product",
    "search_categories",
    "sitemaps",
];

function removeDuplicates(originalArray) {
    var newArray = [];
    var lookupObject = {};

    for (var i in originalArray) {
        const prop = originalArray[i].hasOwnProperty("@id")
            ? "@id"
            : "attribute";
        lookupObject[originalArray[i][prop]] = originalArray[i];
    }

    for (i in lookupObject) {
        newArray.push(lookupObject[i]);
    }
    return newArray;
}

const setProductRelations = (relations, isDuplicate = false) =>
    toPairs(relations).reduce((acc, [relationship, values]) => {
        values.forEach((value, index) => {
            const { productID, relatedProduct, quantity } = value;
            const relationData = {
                relationship,
                quantity,
                isReciprocal: false,
            };

            if (value["@id"] && !isDuplicate) {
                // Use resource IRI for existing relations
                relationData["@id"] = value["@id"];
            }

            if (relatedProduct) {
                relationData.relatedProduct = relatedProduct["@id"];
            } else if (productID) {
                relationData.relatedProduct = productID;
            }

            relationData.displayOrder = index + 1;

            acc.push(relationData);
        });
        return acc;
    }, []);

const setProductResources = (resources, isDuplicate = false) => {
    let productResources = [];

    if (resources) {
        for (let [type, values] of Object.entries(resources)) {
            values.forEach((value, idx) => {
                const { title, description, location } = value;
                const resourceData = {
                    location: location,
                    displayOrder: idx + 1,
                    title: title,
                    description: description,
                };

                if (value["@id"] && !isDuplicate) {
                    // Use resource IRI for existing resources
                    resourceData["@id"] = value["@id"];
                } else {
                    // Set the type for new resources
                    resourceData.type = type;
                }

                productResources.push(resourceData);
            });
        }
    }

    return productResources;
};

const setProductOptions = (options, isDuplicate = false) => {
    let productOptions = [];

    if (options) {
        options.forEach((value, idx) => {
            const { key, name, type } = value;
            const optionData = {
                displayOrder: idx + 1,
                key,
                name,
                type,
            };

            if (value["@id"] && !isDuplicate) {
                // Use resource IRI for existing options
                optionData["@id"] = value["@id"];
            }

            productOptions.push(optionData);
        });
    }

    return productOptions;
};

const setVariantOptions = (options, isDuplicate = false) => {
    let variantOptions = [];

    if (options) {
        options.forEach((value) => {
            const optionData = value;

            if (value?.productOption.hasOwnProperty("@id")) {
                // Use resource IRI for existing options
                if (!isDuplicate) {
                    optionData.productOption = value.productOption["@id"];
                }

                delete optionData["@type"];
                delete optionData.productOption["@type"];
            }

            variantOptions.push(optionData);
        });
    }

    return variantOptions;
};

const getProductResources = (resources) => {
    let productResources = {};

    resources.forEach((resource) => {
        const { type, title, description, location } = resource;
        if (!productResources.hasOwnProperty(type)) {
            productResources[type] = [];
        }
        productResources[type].push({
            "@id": resource["@id"] || null,
            type,
            title,
            location,
            description,
        });
    });

    return productResources;
};

const getProductRelations = (relations) =>
    relations.reduce((acc, rel) => {
        const { relationship, quantity } = rel;
        if (!acc.hasOwnProperty(relationship)) {
            acc[relationship] = [];
        }
        acc[relationship].push({
            "@id": rel["@id"] || null,
            relationship,
            quantity,
            sku: rel?.relatedProduct?.sku,
            label: getProductName(rel?.relatedProduct),
            relatedProduct: rel?.relatedProduct,
        });

        return acc;
    }, {});

const setProductInventories = (inventories) => {
    return (
        inventories &&
        inventories.map((inventory) => {
            if (inventory.createdAt) delete inventory.location;
            return {
                ...inventory,
                ...(!inventory.createdAt && {
                    location: inventory.location.id,
                }),
            };
        })
    );
};

export const addAttributesToFormData = (data, groups) => {
    let formData;
    if (!data) {
        formData = { attributes: [] };
    } else {
        formData = data;
    }

    const formAttributes = formData.attributes.map((attr) => {
        return attr?.attribute && attr?.attribute?.code
            ? attr.attribute.code
            : attr.code;
    });

    groups.forEach((group) => {
        group.attributes.forEach((attr) => {
            const name = attr.code;
            if (!formAttributes.includes(name)) {
                formData.attributes.push(attr);
                formData[name] =
                    attr.defaultValue === 0 || attr.defaultValue?.length > 1
                        ? attr.defaultValue
                        : null;
            }
        });
    });

    return formData;
};

export const formatDataForForm = (data, displayFields = []) => {
    const attrValues = {};

    if (data?.attributes) {
        const formAttributes = data.attributes.map(
            (attr) => attr.attribute.code
        );
        formAttributes.map((code) => (attrValues[`attribute-${code}`] = ""));

        data.attributes.forEach((attr) => {
            const code =
                attr?.attribute && attr?.attribute?.code
                    ? attr.attribute.code
                    : attr.code;
            if (Object.keys(attrValues).includes(`attribute-${code}`)) {
                attrValues[`attribute-${code}`] = attr.value;
            }
        });
    }

    if (!data.seo_title && attrValues["product_name"]) {
        data.seo_title = attrValues["product_name"];
    }

    if (data.resources) {
        data.productResources = getProductResources(data.resources);
    }

    if (data.relations) {
        data.productRelations = getProductRelations(data.relations);
    }

    if (data?.display) {
        displayFields.forEach((field) => {
            data[field] = data.display.includes(field);
        });
    }

    return {
        ...data,
        ...attrValues,
    };
};

export const formatDataForAPI = ({
    data,
    isDuplicate = false,
    isVariant = false,
    displayFields = [],
    additionalPriceTypes = [],
}) => {
    const dataClone = { ...data };

    if (isDuplicate) {
        dataClone.sku = `copy-of-${dataClone.sku}`;
        dataClone.slug = `copy-of-${dataClone.slug}`;
        dataClone.product_name = `Copy of ${dataClone.product_name}`;
        delete dataClone["@id"];
        delete dataClone["@context"];
        delete dataClone["@type"];
        delete dataClone["id"];
        delete dataClone["originId"];

        if (!isVariant) {
            const attrs = dataClone.attributes.map((attr) => {
                if (!isEmpty(attr?.attribute)) {
                    return {
                        attribute: attr?.attribute?.["@id"],
                        dataType: attr.dataType,
                        locale: attr.locale,
                        value:
                            attr?.attribute?.code === "product_name"
                                ? `Copy of ${attr.value}`
                                : attr.value,
                    };
                }
            });

            dataClone.attributes = attrs.filter((a) => !!a);
        }
    }

    if (dataClone?.weight < 1) {
        dataClone.weight = null;
    }

    if (dataClone?.attributes && !isDuplicate) {
        const formAttributes = dataClone.attributes.map((attr) => {
            return attr?.attribute && attr?.attribute?.code
                ? attr.attribute.code
                : attr.code;
        });

        dataClone.attributes.forEach((attr, idx) => {
            const code =
                attr?.attribute && attr?.attribute?.code
                    ? attr.attribute.code
                    : attr.code;
            const id = attr.attribute
                ? attr.attribute.hasOwnProperty("@id")
                    ? attr.attribute["@id"]
                    : attr.attribute
                : attr["@id"];

            if (formAttributes.includes(code)) {
                attr.value =
                    dataClone[`attribute-${code}`] || data[`attribute-${code}`];

                if (attr.input === "integer") {
                    attr.value = parseFloat(attr.value);
                } else if (
                    attr.input === "multiselect" &&
                    !isArray(attr.value)
                ) {
                    attr.value = !!attr.value ? [attr.value] : [];
                }
            }

            if (attr.value === null || isNaN(attr.value)) {
                delete dataClone.attributes[idx];
            } else {
                attr.attribute = id;
            }

            delete dataClone[`attribute-${code}`];
        });

        dataClone.attributes = dataClone.attributes.map((attr) => {
            const attrObj = {
                value: attr.value,
            };
            if (
                attr.hasOwnProperty("@id") &&
                attr["@id"].startsWith("/product-attributes")
            ) {
                attrObj["@id"] = attr["@id"];
            } else {
                attrObj.attribute = attr.attribute;
            }
            return attrObj;
        });

        dataClone.attributes = removeDuplicates(dataClone.attributes);
    }

    if (dataClone.relations) {
        dataClone.relations = setProductRelations(
            dataClone.productRelations,
            isDuplicate
        );
    }

    if (!isVariant && dataClone.productResources) {
        dataClone.resources = setProductResources(
            dataClone.productResources,
            isDuplicate
        );
    }

    if (isVariant) {
        if (isDuplicate) {
            delete dataClone.slug;
            delete dataClone.product_name;

            const formattedOptions = [];

            dataClone.options.forEach((option) => {
                const formattedOption = {
                    productOption: option.productOption["@id"],
                    altValue: option.altValue,
                    displayValue: option.displayValue,
                    identifier: option.identifier,
                };

                formattedOptions.push(formattedOption);
            });

            dataClone.options = formattedOptions;
        }

        if (!isEmpty(dataClone?.variantResources)) {
            dataClone.resources = [];

            Object.values(dataClone?.variantResources).map(
                (variantResource) => {
                    const resourceItems = variantResource.map((item) => {
                        const existingImage = data?.resources?.find(
                            (res) => res.productResource["@id"] === item
                        );

                        const imageData = {
                            productResource: item,
                        };

                        if (existingImage?.hasOwnProperty("@id")) {
                            imageData["@id"] = existingImage["@id"];
                        }

                        return imageData;
                    });

                    dataClone.resources = [
                        ...dataClone.resources,
                        ...resourceItems,
                    ];
                }
            );

            delete dataClone.variantResources;
        } else {
            dataClone.resources = [];
        }
    }

    if (dataClone.options) {
        if (!isVariant) {
            dataClone.options = setProductOptions(
                dataClone.options,
                isDuplicate
            );
        } else {
            dataClone.options = setVariantOptions(
                dataClone.options,
                isDuplicate
            );
        }
    }

    if (dataClone?.attributeSet) {
        dataClone.attributeSet =
            typeof data.attributeSet === "string"
                ? data.attributeSet
                : data.attributeSet["@id"];
    }

    dataClone.inventories = setProductInventories(dataClone.inventories);

    dataClone.display = [];

    displayFields.forEach((field) => {
        if (dataClone[field] === true) {
            dataClone.display.push(field);
        }
    });

    const intValues = [
        "rrp",
        "price",
        "salePrice",
        "clearancePrice",
        "multipleOfSaleQuantity",
        "minimumOrderQuantity",
        "maximumOrderQuantity",
        ...additionalPriceTypes,
    ];

    intValues.map((val) => {
        dataClone[val] = dataClone[val] ? parseInt(dataClone[val]) : null;
    });

    if (dataClone?.bulkPrices?.length > 0) {
        dataClone.bulkPrices = dataClone.bulkPrices.map((price) => {
            return {
                ...price,
                value: parseInt(price.value),
            };
        });
    }

    if (
        !dataClone.multipleOfSaleQuantity ||
        dataClone.multipleOfSaleQuantity < 1
    ) {
        dataClone.multipleOfSaleQuantity = 1;
    }

    if (!dataClone.minimumOrderQuantity || dataClone.minimumOrderQuantity < 1) {
        dataClone.minimumOrderQuantity = 1;
    }

    // productResources/productRelations are only used in the UI and don't need to be passed to the API
    delete dataClone.productResources;
    delete dataClone.productRelations;

    for (const key of Object.keys(dataClone)) {
        if (key.startsWith("resources_")) {
            delete dataClone[key];
        }
    }

    return dataClone;
};

export const convertPricesToCurrency = (data, additionalPriceTypes = []) => {
    data.price = data.price ? (data.price / 100).toFixed(2) : null;
    data.rrp = data.rrp ? (data.rrp / 100).toFixed(2) : null;
    data.salePrice = data.salePrice ? (data.salePrice / 100).toFixed(2) : null;
    data.clearancePrice = data.clearancePrice
        ? (data.clearancePrice / 100).toFixed(2)
        : null;

    if (additionalPriceTypes?.length > 0) {
        additionalPriceTypes.forEach((priceType) => {
            data[priceType] = data[priceType]
                ? (data[priceType] / 100).toFixed(2)
                : null;
        });
    }

    if (data?.bulkPrices?.length > 0) {
        data.bulkPrices = data.bulkPrices.map((price) => {
            return {
                ...price,
                value: (price.value / 100).toFixed(2),
            };
        });
    }

    return data;
};

export const convertPricesToIntegers = (data, additionalPriceTypes = []) => {
    data.price = data.price ? Math.round(parseFloat(data.price) * 100) : null;
    data.rrp = data.rrp ? Math.round(parseFloat(data.rrp) * 100) : null;
    data.salePrice = data.salePrice
        ? Math.round(parseFloat(data.salePrice) * 100)
        : null;
    data.clearancePrice = data.clearancePrice
        ? Math.round(parseFloat(data.clearancePrice) * 100)
        : null;

    if (additionalPriceTypes?.length > 0) {
        additionalPriceTypes.forEach((priceType) => {
            data[priceType] = data[priceType]
                ? Math.round(parseFloat(data[priceType]) * 100)
                : null;
        });
    }

    if (data?.bulkPrices?.length > 0) {
        data.bulkPrices = data.bulkPrices.map((price) => {
            return {
                ...price,
                value: Math.round(parseFloat(price.value) * 100),
            };
        });
    }

    return data;
};

export const convertObjectToOptions = (obj, useKeyAsValue = false) => {
    const options = [];

    if (!!obj) {
        for (let [key, val] of Object.entries(obj)) {
            options.push({
                label: startCase(val.replace(/_/g, " ")),
                value: useKeyAsValue ? key : val,
            });
        }
    }

    return options;
};

export const setDefaultOption = (needle, haystack) => {
    return haystack.find((opt) => opt.value === needle);
};

export const getProductName = (products) => {
    const productNameAttribute = products?.attributes?.find(
        (a) => a.attribute?.code === "product_name"
    );

    return productNameAttribute?.value || "-";
};

export const sanitiseUrlValue = (value) => {
    return encodeURI(
        value
            .split(" ")
            .join("-")
            .replace(/[`~!@#$£€%^&*_|+=?;:'",.()<>{}[\]\\/]/gi, "")
            .replace(/-+/g, "-")
            .toLowerCase()
    );
};

export const checkForDuplicateOptions = (options = [], actions) => {
    const duplicates = [];
    options.forEach((el, i) => {
        options.forEach((element, index) => {
            if (i === index) return null;
            if (element.key === el.key) {
                if (!duplicates.includes(el.key)) {
                    actions.setFieldError(
                        `options.${index}.product`,
                        "There is already a product option for this product and key"
                    );
                    duplicates.push(el.key);
                }
            }
        });
    });

    return duplicates.length > 0;
};
