import React from "react";
import PropTypes from "prop-types";
import RelatedProducts from "./Lists/RelatedProducts";
import Resources from "./Lists/Resources";
import { LIST_TYPES } from "../../ProductForm";
import { convertObjectToOptions } from "../../util";

const ProductListRenderer = ({ currentProduct, groups, type, disabled }) => {
    const listGroups = convertObjectToOptions(groups, true);

    switch (type) {
        case LIST_TYPES.RELATED:
            return (
                <>
                    {listGroups.map((group) => (
                        <RelatedProducts
                            key={`related_${group.value}`}
                            groupLabel={group.label}
                            group={group.value}
                            currentProduct={currentProduct}
                            disabled={disabled}
                        />
                    ))}
                </>
            );
        case LIST_TYPES.RESOURCES:
            return (
                <>
                    {listGroups.map((group) => (
                        <Resources
                            key={`resources_${group.value}`}
                            groupLabel={group.label}
                            group={group.value}
                            disabled={disabled}
                        />
                    ))}
                </>
            );
        default: 
            return null
    }
};

ProductListRenderer.propTypes = {
    groups: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired,
};

export default ProductListRenderer;
