import React from "react";
import Sortable from "react-sortablejs";

import { Field, FieldArray } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVideo } from "@fortawesome/pro-regular-svg-icons/faVideo";
import { faFile } from "@fortawesome/pro-regular-svg-icons/faFile";
import { faGripLines } from "@fortawesome/pro-regular-svg-icons/faGripLines";
import { faTrash } from "@fortawesome/pro-regular-svg-icons/faTrash";

import { Textarea, File, Input, SortableRow } from "@peracto/peracto-ui";
import last from "lodash/last";

const FORMATTED_TITLES = {
    "You Tube": "YouTube Video",
    YouTube: "YouTube Video",
};

const INPUT_FIELDS = ["youtube"];

const ResourceIcon = ({ type, src = "", testId }) => {
    switch (type) {
        case "image":
            return (
                <img
                    src={src}
                    style={{ width: "100%" }}
                    alt={src}
                    data-testid={testId}
                />
            );
        case "video":
            return <FontAwesomeIcon icon={faVideo} title={src} />;
        default:
            return <FontAwesomeIcon icon={faFile} title={src} />;
    }
};

const Resources = ({ groupLabel = "", group, disabled }) => {
    const InputComponent = INPUT_FIELDS.includes(group) ? Input : File;

    return (
        <Field name={`productResources.${group}`}>
            {({ field, form }) => (
                <>
                    <FieldArray name={field.name}>
                        {({ move, push, remove }) => (
                            <>
                                <div className="form-group">
                                    <label className="mb-1">
                                        {FORMATTED_TITLES[groupLabel] ||
                                            `${
                                                groupLabel
                                                    .toLowerCase()
                                                    .endsWith("s")
                                                    ? groupLabel
                                                    : `${groupLabel}s`
                                            }`}
                                    </label>
                                    {field?.value?.length > 0 && (
                                        <table className="table table-sm mb-1">
                                            <colgroup>
                                                <col width="5%" />
                                                <col width="10%" />
                                                <col width="30%" />
                                                <col width="40%" />
                                                <col width="15%" />
                                            </colgroup>

                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th></th>
                                                    <th>Title</th>
                                                    <th>Description</th>
                                                    <th className="text-right">
                                                        Remove
                                                    </th>
                                                </tr>
                                            </thead>

                                            <Sortable
                                                tag="tbody"
                                                onChange={(
                                                    order,
                                                    sortable,
                                                    evt
                                                ) => {
                                                    if (!disabled) {
                                                        move(
                                                            evt.oldIndex,
                                                            evt.newIndex
                                                        );
                                                    }
                                                }}
                                                options={{
                                                    animation: 100,
                                                    handle: ".handle",
                                                }}
                                            >
                                                {field.value.map(
                                                    (item, index) => (
                                                        <SortableRow
                                                            key={index}
                                                            data-id={index}
                                                            data-testid={`${group}s__item`}
                                                        >
                                                            <td>
                                                                <span
                                                                    className="handle"
                                                                    data-testid={`${group}_item_${index}_slider-handle`}
                                                                >
                                                                    <FontAwesomeIcon
                                                                        icon={
                                                                            faGripLines
                                                                        }
                                                                    />
                                                                </span>
                                                            </td>
                                                            <td className="text-center">
                                                                <span
                                                                    className="d-block overflow-hidden"
                                                                    style={{
                                                                        maxWidth:
                                                                            "60px",
                                                                    }}
                                                                >
                                                                    <ResourceIcon
                                                                        type={
                                                                            group
                                                                        }
                                                                        src={
                                                                            item.location
                                                                        }
                                                                        testId={`${group}_item_${index}_image`}
                                                                    />
                                                                </span>
                                                            </td>
                                                            <td>
                                                                <Textarea
                                                                    name={`productResources.${group}.${index}.title`}
                                                                    label=""
                                                                    placeholder="Title"
                                                                    testId={`${group}_item_${index}_title`}
                                                                    disabled={
                                                                        disabled
                                                                    }
                                                                />
                                                            </td>
                                                            <td>
                                                                <Textarea
                                                                    name={`productResources.${group}.${index}.description`}
                                                                    label=""
                                                                    placeholder="Description"
                                                                    testId={`${group}_item_${index}_description`}
                                                                    disabled={
                                                                        disabled
                                                                    }
                                                                />
                                                            </td>
                                                            <td className="text-right">
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-sm btn-danger"
                                                                    onClick={() => {
                                                                        if (
                                                                            !disabled
                                                                        ) {
                                                                            remove(
                                                                                index,
                                                                                1
                                                                            );
                                                                        }
                                                                    }}
                                                                    disabled={
                                                                        disabled
                                                                    }
                                                                    data-testid={`${group}_item_${index}_remove`}
                                                                >
                                                                    <FontAwesomeIcon
                                                                        icon={
                                                                            faTrash
                                                                        }
                                                                    />
                                                                </button>
                                                            </td>
                                                        </SortableRow>
                                                    )
                                                )}
                                            </Sortable>
                                        </table>
                                    )}
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <InputComponent
                                                testId={`${group}s__input`}
                                                name={`resources_${groupLabel}`}
                                                disabled={disabled}
                                                placeholder={`Add a new ${
                                                    FORMATTED_TITLES[
                                                        groupLabel
                                                    ] ||
                                                    groupLabel.toLowerCase()
                                                } to the product`}
                                                onKeyPress={(e) => {
                                                    if (e.key === "Enter") {
                                                        e.preventDefault();

                                                        if (
                                                            e.target.value
                                                                .length > 0
                                                        ) {
                                                            const filename =
                                                                last(
                                                                    e.target.value.split(
                                                                        "/"
                                                                    )
                                                                );

                                                            push({
                                                                location:
                                                                    e.target
                                                                        .value,
                                                                title: decodeURI(
                                                                    filename
                                                                ),
                                                                description: "",
                                                            });

                                                            e.target.value = "";
                                                        }
                                                    }
                                                }}
                                                {...(!INPUT_FIELDS.includes(
                                                    group
                                                ) && {
                                                    onSelectCallback: (
                                                        value
                                                    ) => {
                                                        if (value.length > 0) {
                                                            const filename =
                                                                last(
                                                                    value.split(
                                                                        "/"
                                                                    )
                                                                );
                                                            push({
                                                                location: value,
                                                                title: decodeURI(
                                                                    filename
                                                                ),
                                                                description: "",
                                                            });
                                                        }
                                                    },
                                                })}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </FieldArray>
                </>
            )}
        </Field>
    );
};

export default Resources;
