import React from 'react'

import { Field, FieldArray } from 'formik'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/pro-regular-svg-icons/faTrash'
import { faPlus } from '@fortawesome/pro-regular-svg-icons/faPlus'

import { Input } from '@peracto/peracto-ui'
import { useConfig } from '@peracto/peracto-config'

const defaultBulkPrice = {
    quantity: 2,
    value: 0,
    type: 'absolute',
}

const BulkPricing = ({ disabled }) => {
    const config = useConfig()

    const { products } = config.get('features', {})
    const showPricesAsCurrency = products?.showPricesAsCurrency ?? true

    return (
        <Field name={`bulkPrices`}>
            {({ field }) => (
                <>
                    <FieldArray name={field.name}>
                        {({ push, remove }) => (
                            <>
                                <div className="form-group" data-testid={`bulk-price-section`}>
                                    <label className="mb-1">Bulk Pricing</label>
                                    {field?.value?.length > 0 && (
                                        <table className="table table-sm mb-1">
                                            <colgroup>
                                                <col width="40%" />
                                                <col width="40%" />
                                                <col width="20%" />
                                            </colgroup>

                                            <thead>
                                                <tr>
                                                    <th>Quantity</th>
                                                    <th>Price</th>
                                                    <th className="text-right">Remove</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {field.value.map((item, index) => (
                                                    <tr
                                                        key={index}
                                                        data-testid={`bulk-price-entry-${index}`}
                                                    >
                                                        <td>
                                                            <Input
                                                                name={`bulkPrices.${index}.quantity`}
                                                                label=""
                                                                type="number"
                                                                min={2}
                                                                placeholder="Quantity"
                                                                onKeyDown={evt =>
                                                                    ['e', 'E', '+', '-'].includes(
                                                                        evt.key
                                                                    ) && evt.preventDefault()
                                                                }
                                                                testId={`bulk-price-${index}-quantity`}
                                                            />
                                                        </td>
                                                        <td>
                                                            <Input
                                                                name={`bulkPrices.${index}.value`}
                                                                label=""
                                                                min={0}
                                                                placeholder="Price"
                                                                prefix={
                                                                    showPricesAsCurrency && '\u00A3'
                                                                }
                                                                onKeyDown={evt =>
                                                                    ['e', 'E', '+', '-'].includes(
                                                                        evt.key
                                                                    ) && evt.preventDefault()
                                                                }
                                                                testId={`bulk-price-${index}-value`}
                                                            />
                                                        </td>
                                                        <td className="text-right">
                                                            <button
                                                                type="button"
                                                                className="btn btn-sm btn-danger"
                                                                onClick={() => {
                                                                    remove(index, 1)
                                                                }}
                                                                data-testid={`remove-bp-entry-${index}`}
                                                            >
                                                                <FontAwesomeIcon icon={faTrash} />
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    )}
                                    {!field.value ||
                                        (field.value?.length === 0 && (
                                            <p className="py-3 my-3 text-center">
                                                No Bulk Prices applied
                                            </p>
                                        ))}

                                    <div className="d-flex align-items-center justify-content-end mt-4">
                                        <button
                                            type="button"
                                            className="btn btn-primary"
                                            onClick={() => {
                                                if (!disabled) {
                                                    push(defaultBulkPrice)
                                                }
                                            }}
                                            disabled={disabled}
                                            data-testid="add-bulk-price"
                                        >
                                            <FontAwesomeIcon icon={faPlus} /> Add new Bulk Price
                                        </button>
                                    </div>
                                </div>
                            </>
                        )}
                    </FieldArray>
                </>
            )}
        </Field>
    )
}

export default BulkPricing
