import React, { useMemo } from "react";

const generatePresetUrl = (url, preset) => {
    if (!url?.includes("cloudfront")) return url;
    const split = url.split("/images");
    split.splice(1, 0, `/images/presets/${preset}`);
    return split.join("");
};

const breakpoints = {
    sm: "576px",
    md: "768px",
    lg: "992px",
    xl: "1210px",
};

export const Image = (props) => {
    const {
        src,
        presets = {},
        maxImageHeight,
        alt = "",
        title = "",
        ...restProps
    } = props;

    return useMemo(
        () => (
            <div data-testid="image" {...restProps}>
                <picture className="h-full w-full">
                    {["xl", "lg", "md", "sm"].map((breakpoint) =>
                        !!breakpoints[breakpoint] && !!presets[breakpoint] ? (
                            <source
                                key={`image_${breakpoint}`}
                                srcSet={generatePresetUrl(
                                    src,
                                    presets[breakpoint]
                                )}
                                media={`(min-width: ${breakpoints[breakpoint]})`}
                                data-testid={`image-source-${breakpoint}`}
                            />
                        ) : null
                    )}

                    {!!presets["base"] && (
                        <source
                            srcSet={generatePresetUrl(src, presets["base"])}
                            media={`(min-width: 0px)`}
                            data-testid="image-source-base"
                        />
                    )}
                    <img
                        className={`w-full h-full`}
                        src={src}
                        alt={alt}
                        title={title}
                    />
                </picture>
            </div>
        ),
        [alt, src, title, presets, restProps]
    );
};
