import React from "react";

import PropTypes from "prop-types";

import {
    Input,
    Checkbox,
    Select,
    Tags,
    Radio,
    Textarea,
    RichText,
} from "@peracto/peracto-ui";

const getSelectInputOption = (option) => {
    if (!!option?.value) {
        const selectOption = { label: option.label, value: option.value };
        if (option.value.startsWith("#") || option.value.startsWith("rgb")) {
            selectOption.label = (
                <div
                    className="d-flex"
                    data-testid={`${selectOption.label
                        .toLowerCase()
                        .replaceAll(" ", "-")}`}
                >
                    <span
                        style={{
                            height: "20px",
                            width: "20px",
                            borderRadius: "50%",
                            marginRight: "10px",
                            display: "inline-block",
                            backgroundColor: option.value,
                        }}
                    ></span>
                    {option.label}
                </div>
            );
        } else if (option.value.startsWith("http")) {
            selectOption.label = (
                <div
                    className="d-flex"
                    data-testid={`${selectOption.label
                        .toLowerCase()
                        .replaceAll(" ", "-")}`}
                >
                    <img
                        style={{
                            width: "20px",
                            marginRight: "10px",
                        }}
                        alt=""
                        src={option.value}
                    ></img>
                    {option.label}
                </div>
            );
        }

        return selectOption;
    } else {
        return { label: option.label, value: option.value || option.label };
    }
};

const AttributeSetRenderer = ({ formData, isDisabled, coreFields = [] }) => {
    if (formData) {
        return (
            <>
                {formData.map((field, idx) => {
                    let input;
                    let inputType = "text";
                    let fieldName = field.code;
                    let label = field.label;
                    const isRequired = field.required ?? false;

                    if (coreFields.includes(fieldName)) {
                        return false;
                    }

                    switch (field.input) {
                        case "checkbox":
                            input = (
                                <Checkbox
                                    name={`attribute-${fieldName}`}
                                    label={label}
                                    help={field.description || null}
                                    required={isRequired}
                                    disabled={isDisabled}
                                />
                            );
                            break;

                        case "multiselect":
                            input = (
                                <Tags
                                    name={`attribute-${fieldName}`}
                                    label={label}
                                    options={
                                        field.options
                                            ? field.options.map((opt) => ({
                                                  label: opt.label,
                                                  value: opt.value || opt.label,
                                              }))
                                            : []
                                    }
                                    help={field.description || null}
                                    required={isRequired}
                                    disabled={isDisabled}
                                    testId={`${label
                                        .toLowerCase()
                                        .replaceAll(" ", "-")}-${
                                        field.input
                                    }-attribute`}
                                />
                            );
                            break;

                        case "select":
                        case "swatch":
                            input = (
                                <Select
                                    name={`attribute-${fieldName}`}
                                    label={label}
                                    options={field.options.map((opt) => {
                                        return getSelectInputOption(opt);
                                    })}
                                    help={field.description || null}
                                    required={isRequired}
                                    disabled={isDisabled}
                                    allowClear={true}
                                    testId={`${label
                                        .toLowerCase()
                                        .replaceAll(" ", "-")}-${
                                        field.input
                                    }-attribute`}
                                />
                            );
                            break;

                        case "yesno":
                            input = (
                                <Select
                                    name={`attribute-${fieldName}`}
                                    label={label}
                                    options={[
                                        {
                                            label: "Yes",
                                            value: "Yes",
                                        },
                                        {
                                            label: "No",
                                            value: "No",
                                        },
                                    ]}
                                    help={field.description || null}
                                    required={isRequired}
                                    disabled={isDisabled}
                                    testId={`${label
                                        .toLowerCase()
                                        .replaceAll(" ", "-")}-${
                                        field.input
                                    }-attribute`}
                                />
                            );
                            break;

                        case "radio":
                            input = (
                                <Radio
                                    name={`attribute-${fieldName}`}
                                    label={label}
                                    options={field.options.map((opt) => ({
                                        label: opt.label,
                                        value: opt.value || opt.label,
                                    }))}
                                    help={field.description || null}
                                    required={isRequired}
                                    disabled={isDisabled}
                                />
                            );
                            break;

                        case "textarea":
                            input = (
                                <Textarea
                                    name={`attribute-${fieldName}`}
                                    label={label}
                                    type={inputType}
                                    help={field.description || null}
                                    required={isRequired}
                                    disabled={isDisabled}
                                    testId={`${label
                                        .toLowerCase()
                                        .replaceAll(
                                            " ",
                                            "-"
                                        )}-textarea-attribute`}
                                />
                            );
                            break;

                        case "textarea_rte":
                            input = (
                                <RichText
                                    name={`attribute-${fieldName}`}
                                    label={label}
                                    type={inputType}
                                    help={field.description || null}
                                    required={isRequired}
                                    disabled={isDisabled}
                                />
                            );
                            break;

                        case "integer":
                            inputType = "number";
                            break;

                        case "file":
                            inputType = "file";
                            break;

                        case "email":
                            inputType = "email";
                            break;

                        case "date":
                            inputType = "date";
                            break;

                        case "telephone":
                            inputType = "tel";
                            break;

                        default:
                            break;
                    }

                    if (!input) {
                        input = (
                            <Input
                                name={`attribute-${fieldName}`}
                                label={label}
                                type={inputType}
                                help={field.description || null}
                                required={isRequired}
                                disabled={isDisabled}
                                testId={`${label
                                    .toLowerCase()
                                    .replaceAll(
                                        " ",
                                        "-"
                                    )}-${inputType}-attribute`}
                            />
                        );
                    }

                    return (
                        <React.Fragment key={`${label}_${idx}`}>
                            {input}
                        </React.Fragment>
                    );
                })}
            </>
        );
    } else {
        return null;
    }
};

AttributeSetRenderer.propTypes = {
    formData: PropTypes.array,
};

export default AttributeSetRenderer;
