import React from "react";

import isObjectLike from "lodash/isObjectLike";

import sortBy from "lodash/sortBy";
import last from "lodash/last";

import { Button } from "../../../../components/Button";
import { Price } from "../../../../components/Price";
import { Image } from "../../../../components/Image";

export const ProductCardVertical = (props) => {
    const {
        children,
        product,
        link,
        loggedIn,
        productNameProps,
        separatorProps,
        incTax,
        imagePresets,
        imageProps,
        onAddToWishlist,
        onClick,
        ...restProps
    } = props;

    const productImages = product.resources?.filter((r) => r.type === "image");
    // const productAttributes = product.temp.find((a) => a.attribute.code === 'product_features')
    const firstImage = productImages
        ? sortBy(productImages, ["displayOrder"])[0]
        : null;
    const orderedPrices = [];
    const priceOrder = ["price", "salePrice", "clearancePrice"];

    priceOrder.forEach((p) => {
        if (product.prices[p]) orderedPrices.push(product.prices[p]);
    });

    const priceToPay = last(orderedPrices) || product.prices?.rrp;

    return (
        <div
            {...restProps}
            className={`${restProps?.className || ""}`}
            data-testid={`product-card`}
        >
            <div className={``} data-testid="product-card-image-section">
                <a href={`/products/${product.slug}`}>
                    <Image
                        className="w-full border border-brand-grey mb-[10px]"
                        {...(isObjectLike(product.attributes) && {
                            alt: product.attributes.product_name,
                        })}
                        src={firstImage?.location || ""}
                        data-testid={`product-card__media`}
                        presets={imagePresets}
                        {...imageProps}
                    />
                </a>
            </div>
            <div className={``} data-testid="product-card-feature-section">
                <a href={`/products/${product.slug}`}>
                    <p
                        {...productNameProps}
                        className={`!text-[14px] leading-[18px] mb-[10px] font-semibold ${productNameProps?.className}`}
                        data-testid={`product-card__name`}
                    >
                        {isObjectLike(product.attributes) &&
                            product.attributes.product_name}
                    </p>
                </a>
            </div>
            <div
                data-testid="product-card-pricing-section"
                className="flex-grow flex flex-col justify-end"
            >
                {product.prices && (
                    <div className={`flex items-center`}>
                        <Price
                            data-testid="price-to-pay-inc-tax"
                            includeTax={true}
                            labelClassName={`text-[16px] sm:text-[20px] lg:text-[24px] leading-[24px] sm:leading-[24px] lg:leading-[26px]`}
                            price={{
                                value: {
                                    inc: priceToPay?.inc || 0,
                                    ex: priceToPay?.exc || 0,
                                },
                                label: product?.variants ? "From " : "",
                            }}
                            className="mr-[10px] text-[20px] font-semibold"
                        />
                        <div
                            className={`flex flex-col text-brand-black text-[8px] sm:text-[8px] lg:text-[10px] font-semibold leading-[8px] sm:leading-[8px] lg:leading-[10px]`}
                        >
                            <span>INC</span>
                            <span>VAT</span>
                        </div>
                    </div>
                )}

                <Button
                    className={`mt-[10px] w-full`}
                    variantColor={`secondary`}
                    variantStyle={`solid`}
                    data-testid="add-to-basket"
                >
                    View Product
                </Button>
            </div>
        </div>
    );
};
ProductCardVertical.defaultProps = {
    incTax: true,
    loggedIn: false,
    link: function () {
        console.log("clicking product link");
    },
};
