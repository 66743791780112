const { darken, lighten } = require("polished");

// Used so hover colors get automatically updated
const brandPrimaryColor = "#CD1719";
const brandSecondaryColor = "#283583";
const brandTertiaryColor = "#76B82A";
const brandQuaternaryColor = "#009FE3";
const brandBlackColor = "#303030";

const colors = {
  // Base colors
  "brand-primary": brandPrimaryColor,
  "brand-secondary": brandSecondaryColor,
  "brand-tertiary": brandTertiaryColor,
  "brand-quaternary": brandQuaternaryColor,
  "brand-success": "#2DB200",
  "brand-danger": "#E30613",
  "brand-warning": "#F49C10",
  "brand-info": "#0697E3",
  "brand-pale-danger": "#FED7D7",
  "brand-white": "#FFFFFF",
  "brand-light-grey": "#F4F4F4",
  "brand-grey": "#E6E6E6",
  "brand-dark-grey": "#505050",
  "brand-black": brandBlackColor,

  // Hover colors
  "brand-primary-lighten": lighten(0.05, brandPrimaryColor),
  "brand-primary-darken": darken(0.05, brandPrimaryColor),

  "brand-secondary-lighten": lighten(0.05, brandSecondaryColor),
  "brand-secondary-darken": darken(0.05, brandSecondaryColor),

  "brand-tertiary-lighten": lighten(0.05, brandTertiaryColor),
  "brand-tertiary-darken": darken(0.05, brandTertiaryColor),

  "brand-quaternary-lighten": lighten(0.05, brandQuaternaryColor),
  "brand-quaternary-darken": darken(0.05, brandQuaternaryColor),

  "brand-black-lighten": lighten(0.05, brandBlackColor),
  "brand-black-darken": darken(0.05, brandBlackColor),
};

const fonts = {
  heading: ["Montserrat, sans-serif"],
  body: ["Montserrat, sans-serif"],
};

module.exports = { colors, fonts };
