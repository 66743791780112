import React, { useState } from "react";
import AsyncSelect from "react-select/async";
import Sortable from "react-sortablejs";
import { Field, FieldArray } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGripLines } from "@fortawesome/pro-regular-svg-icons/faGripLines";
import { faTrash } from "@fortawesome/pro-regular-svg-icons/faTrash";

import debounce from "debounce-promise";

import { GET_LIST, useClient } from "@peracto/client";
import { SortableRow, Input } from "@peracto/peracto-ui";

const RelatedProducts = ({
    groupLabel = "",
    group,
    currentProduct,
    disabled,
}) => {
    const [asyncProducts, setAsyncProducts] = useState();
    const { client } = useClient();

    const fetchProducts = async (inputValue, fieldVal) => {
        const { data } = await client(GET_LIST, "products", {
            id: "products",
            filter: {
                productName: inputValue,
            },
        });

        setAsyncProducts(data);

        const selectedValues = fieldVal ? fieldVal : [];
        const selectedIDs = selectedValues.map((val) => val.sku);

        if (currentProduct) {
            selectedIDs.push(currentProduct);
        }

        let values = data.filter(
            (product) => !selectedIDs.includes(product.sku)
        );

        return values.map((product) => ({
            label: product.name,
            value: product.id,
        }));
    };

    const debouncedFetchProducts = debounce(fetchProducts, 200);

    return (
        <Field name={`productRelations.${group}`}>
            {({ field, form }) => {
                return (
                    <>
                        <FieldArray name={field.name}>
                            {({ move }) => (
                                <div
                                    className="form-group"
                                    data-testid={`${group}-section`}
                                >
                                    <label className="mb-1">{groupLabel}</label>
                                    {field?.value?.length > 0 && (
                                        <table className="table table-sm">
                                            <colgroup>
                                                <col width="10%" />
                                                <col width="45%" />
                                                <col width="20%" />
                                                <col width="15%" />
                                                <col width="10%" />
                                            </colgroup>

                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>Name</th>
                                                    <th>SKU</th>
                                                    <th>QTY</th>
                                                    <th className="text-right">
                                                        Remove
                                                    </th>
                                                </tr>
                                            </thead>

                                            <Sortable
                                                tag="tbody"
                                                onChange={(
                                                    order,
                                                    sortable,
                                                    evt
                                                ) => {
                                                    if (!disabled) {
                                                        move(
                                                            evt.oldIndex,
                                                            evt.newIndex
                                                        );
                                                    }
                                                }}
                                                options={{
                                                    animation: 100,
                                                    handle: ".handle",
                                                }}
                                            >
                                                {field.value.map(
                                                    (item, index) => (
                                                        <SortableRow
                                                            key={index}
                                                            data-id={index}
                                                            data-testid={`${group}_entry_${index}`}
                                                        >
                                                            <td>
                                                                <span
                                                                    className="handle"
                                                                    data-testid={`${group}_entry_${index}_slider-handle`}
                                                                >
                                                                    <FontAwesomeIcon
                                                                        icon={
                                                                            faGripLines
                                                                        }
                                                                    />
                                                                </span>
                                                            </td>
                                                            <td
                                                                data-testid={`${group}-entry-${index}-name`}
                                                            >
                                                                {item?.label}
                                                            </td>
                                                            <td
                                                                data-testid={`${group}-entry-${index}-sku`}
                                                            >
                                                                {item?.sku}
                                                            </td>
                                                            <td>
                                                                <Input
                                                                    name={`productRelations.${group}.${index}.quantity`}
                                                                    type="number"
                                                                    min="1"
                                                                    label=""
                                                                    placeholder=""
                                                                    required
                                                                    disabled={
                                                                        disabled
                                                                    }
                                                                    onBlur={(
                                                                        e
                                                                    ) => {
                                                                        if (
                                                                            e
                                                                                .target
                                                                                .value <
                                                                            1
                                                                        ) {
                                                                            e.target.value = 1;
                                                                        }
                                                                    }}
                                                                    testId={`${group}-entry-${index}-quantity`}
                                                                />
                                                            </td>
                                                            <td className="text-right">
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-sm btn-danger"
                                                                    disabled={
                                                                        disabled
                                                                    }
                                                                    onClick={() => {
                                                                        if (
                                                                            !disabled
                                                                        ) {
                                                                            const values =
                                                                                [
                                                                                    ...field.value,
                                                                                ];
                                                                            values.splice(
                                                                                index,
                                                                                1
                                                                            );
                                                                            form.setFieldValue(
                                                                                `productRelations.${group}`,
                                                                                [
                                                                                    ...values,
                                                                                ]
                                                                            );
                                                                        }
                                                                    }}
                                                                    data-testid={`remove-${group}-entry-${index}`}
                                                                >
                                                                    <FontAwesomeIcon
                                                                        icon={
                                                                            faTrash
                                                                        }
                                                                    />
                                                                </button>
                                                            </td>
                                                        </SortableRow>
                                                    )
                                                )}
                                            </Sortable>
                                        </table>
                                    )}
                                </div>
                            )}
                        </FieldArray>
                        <div className="row">
                            <div
                                className="col-12"
                                data-testid={`${group}__dropdown`}
                            >
                                <AsyncSelect
                                    className="w-100 mb-3"
                                    classNamePrefix="list"
                                    loadOptions={(input) =>
                                        debouncedFetchProducts(
                                            input,
                                            field.value
                                        )
                                    }
                                    isSearchable={true}
                                    isDisabled={disabled}
                                    onChange={(option) => {
                                        const selectedValue =
                                            asyncProducts.filter(
                                                (attr) =>
                                                    attr.id === option.value
                                            );
                                        const fieldValues = field.value
                                            ? [...field.value]
                                            : [];

                                        fieldValues.push({
                                            productID: selectedValue[0]["@id"],
                                            quantity: 1,
                                            label: selectedValue[0].name,
                                            sku: selectedValue[0].sku,
                                        });
                                        form.setFieldValue(
                                            `productRelations.${group}`,
                                            [...fieldValues]
                                        );
                                    }}
                                    value={null}
                                    placeholder="Search for Products to add..."
                                    noOptionsMessage={({ inputValue }) => {
                                        if (inputValue.length > 0) {
                                            return `No results found for '${inputValue}'.`;
                                        } else {
                                            return "Enter text to begin searching.";
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </>
                );
            }}
        </Field>
    );
};

export default RelatedProducts;
