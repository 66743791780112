const sharedClasses =
  "flex items-center justify-center py-[12] px-[30px] text-[14px] leading-[45px] h-[45px] hover:no-underline uppercase w-auto font-semibold transition-color duration-200 disabled:cursor-not-allowed disabled:opacity-30";

export const primary = {
  solidClasses: `${sharedClasses} bg-brand-primary text-brand-white hover:bg-brand-primary-darken hover:text-brand-white`,
  outlineClasses: `${sharedClasses} bg-none text-brand-primary border-[2px] border-brand-primary hover:bg-brand-primary hover:text-brand-white active:bg-brand-primary`,
};

export const secondary = {
  solidClasses: `${sharedClasses} bg-brand-secondary text-brand-white hover:bg-brand-secondary-darken hover:text-brand-white`,
  outlineClasses: `${sharedClasses} bg-none text-brand-secondary border-[2px] border-brand-secondary hover:bg-brand-secondary hover:text-brand-white active:bg-brand-secondary`,
};

export const tertiary = {
  solidClasses: `${sharedClasses} bg-brand-black text-brand-white hover:bg-brand-black-darken hover:text-brand-white`,
  outlineClasses: `${sharedClasses} bg-none text-brand-secondary border-[2px] border-brand-secondary hover:bg-brand-secondary hover:text-brand-white active:bg-brand-secondary`,
};
