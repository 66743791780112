import React from "react";
import { twMerge } from "tailwind-merge";

/** The Price component is used to format a price and label pair. Passing it configuration such as currency, strikethrough, include tax etc. allows you to build complex pricing components. */
export const Price = (props) => {
    const {
        currency,
        currencyAlignment,
        price,
        isUnderPrice,
        showLabel,
        labelAlignment,
        strikethroughLabel,
        strikethroughValue,
        strikethroughBoth,
        includeTax,
        showDecimals,
        parseAsPence,
        commaSeparation = false,
        className,
        labelClassName = "",
        ...restProps
    } = props;

    const incTaxPrice = () => {
        let p = price.value.inc;
        if (parseAsPence) p = p / 100;
        if (showDecimals) p = p.toFixed(2);
        else p = Math.round(p);
        return commaSeparation
            ? p.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            : p;
    };
    const excTaxPrice = () => {
        let p = price.value.ex;
        if (parseAsPence) p = p / 100;
        if (showDecimals) p = p.toFixed(2);
        else p = Math.round(p);
        return commaSeparation
            ? p.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            : p;
    };

    return (
        <div
            className={twMerge(
                `inline-flex align-center whitespace-pre ${
                    labelAlignment === `left` ? "flex-row" : "flex-row-reverse"
                } ${strikethroughBoth ? `line-through` : ``} ${className}`
            )}
            data-testid="price"
            {...restProps}
        >
            {showLabel && price.label && (
                <span
                    className={twMerge(
                        `${
                            strikethroughLabel ? `line-through` : ``
                        } ${labelClassName}`
                    )}
                    data-testid="price__label"
                >
                    {price.label}
                </span>
            )}
            <span
                className={`flex ${strikethroughValue ? `line-through` : ``} ${
                    currencyAlignment === `left`
                        ? `flex-row`
                        : `flex-row-reverse`
                }`}
            >
                <span
                    className={
                        className
                            ? `${className} !mr-0`
                            : isUnderPrice
                            ? `text-[12px] leading-[20px]`
                            : `text-[16px] sm:text-[20px] lg:text-[24px] leading-[24px] sm:leading-[24px] lg:leading-[26px]`
                    }
                    data-testid="price__currency"
                >
                    {currency}
                </span>
                <span
                    className={
                        className
                            ? `${className} !mr-0`
                            : isUnderPrice
                            ? `text-[12px] leading-[20px]`
                            : `text-[16px] sm:text-[20px] lg:text-[24px] leading-[24px] sm:leading-[24px] lg:leading-[26px]`
                    }
                    data-testid="price__price"
                >
                    {includeTax ? incTaxPrice() : excTaxPrice()}
                </span>
            </span>
        </div>
    );
};

Price.defaultProps = {
    currency: `£`,
    currencyAlignment: `left`,
    showLabel: true,
    labelAlignment: `left`,
    strikethroughLabel: false,
    strikethroughValue: false,
    strikethroughBoth: false,
    includeTax: false,
    showDecimals: true,
    parseAsPence: true,
};
