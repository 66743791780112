import React, { useState } from "react";
import AsyncSelect from "react-select/async";
import { Field, FieldArray } from "formik";
import debounce from "debounce-promise";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/pro-regular-svg-icons/faTrash";

import { GET_LIST, useClient } from "@peracto/client";

import { Input } from "@peracto/peracto-ui";

const LocationManagement = ({ disabled }) => {
    const [locations, setLocations] = useState();
    const { client } = useClient();

    const fetchLocations = async (inputValue, fieldVal) => {
        const { data } = await client(GET_LIST, "locations", {
            id: "locations",
            filter: {
                name: inputValue,
                "locationTypes.type": "warehouse",
            },
        });

        setLocations(data);

        const selectedValues = fieldVal
            ? fieldVal.map((item) => item.location)
            : [];
        const selectedIDs = selectedValues.map((val) =>
            val.hasOwnProperty("@id") ? val["@id"] : val.id
        );

        let values = data.filter((item) => !selectedIDs.includes(item.id));

        return values.map((item) => ({
            label: item.name,
            value: item.id,
        }));
    };

    const debouncedFetchLocations = debounce(fetchLocations, 200);

    return (
        <Field name="inventories">
            {({ field, form }) =>
                form.values.manageStock ? (
                    <FieldArray name={field.name}>
                        {({ push, remove }) => (
                            <>
                                <div className="form-group">
                                    {field?.value?.length > 0 ? (
                                        <table className="table table-sm">
                                            <colgroup>
                                                <col width="30%" />
                                                <col width="30%" />
                                                <col width="30%" />
                                                <col width="10%" />
                                            </colgroup>

                                            <thead>
                                                <tr>
                                                    <th>Location</th>
                                                    <th>Stock Level</th>
                                                    <th>Out Of Stock Level</th>
                                                    <th className="text-right">
                                                        Remove
                                                    </th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {field.value.map(
                                                    (item, index) => (
                                                        <tr
                                                            key={index}
                                                            data-id={`location_${index}`}
                                                        >
                                                            <td
                                                                data-testid={`warehouse-${index}-name`}
                                                            >
                                                                {
                                                                    item
                                                                        ?.location
                                                                        ?.name
                                                                }
                                                            </td>
                                                            <td>
                                                                <Input
                                                                    name={`${field.name}.${index}.stockLevel`}
                                                                    type="number"
                                                                    min="0"
                                                                    label=""
                                                                    placeholder=""
                                                                    required
                                                                    onBlur={(
                                                                        e
                                                                    ) => {
                                                                        if (
                                                                            e
                                                                                .target
                                                                                .value <
                                                                            0
                                                                        ) {
                                                                            e.target.value = 0;
                                                                        }
                                                                    }}
                                                                    disabled={
                                                                        disabled
                                                                    }
                                                                    testId={`location-${index}-stock-level`}
                                                                />
                                                            </td>
                                                            <td>
                                                                <Input
                                                                    name={`${field.name}.${index}.outOfStockLevel`}
                                                                    type="number"
                                                                    min="0"
                                                                    label=""
                                                                    placeholder=""
                                                                    required
                                                                    onBlur={(
                                                                        e
                                                                    ) => {
                                                                        if (
                                                                            e
                                                                                .target
                                                                                .value <
                                                                            0
                                                                        ) {
                                                                            e.target.value = 0;
                                                                        }
                                                                    }}
                                                                    disabled={
                                                                        disabled
                                                                    }
                                                                    testId={`location-${index}-out-of-stock-level`}
                                                                />
                                                            </td>
                                                            <td className="text-right">
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-sm btn-danger"
                                                                    onClick={() => {
                                                                        remove(
                                                                            index
                                                                        );
                                                                    }}
                                                                    data-testid={`remove-location-entry-${index}`}
                                                                    disabled={
                                                                        disabled
                                                                    }
                                                                >
                                                                    <FontAwesomeIcon
                                                                        icon={
                                                                            faTrash
                                                                        }
                                                                    />
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    )
                                                )}
                                            </tbody>
                                        </table>
                                    ) : (
                                        <div className="py-2 text-center">
                                            This product isn't currently
                                            assigned to any locations.
                                        </div>
                                    )}
                                </div>

                                <div className="row">
                                    <div
                                        className="col-12"
                                        data-testid="location-dropdown"
                                    >
                                        <AsyncSelect
                                            className="w-100 mb-3"
                                            classNamePrefix="list"
                                            loadOptions={(input) =>
                                                debouncedFetchLocations(
                                                    input,
                                                    field.value
                                                )
                                            }
                                            isSearchable={true}
                                            onChange={(option) => {
                                                const selectedValue =
                                                    locations.filter(
                                                        (attr) =>
                                                            attr.id ===
                                                            option.value
                                                    );

                                                push({
                                                    location: {
                                                        id: selectedValue[0][
                                                            "@id"
                                                        ],
                                                        name: selectedValue[0]
                                                            .name,
                                                    },
                                                    stockLevel: 1,
                                                    outOfStockLevel: 0,
                                                });
                                            }}
                                            value={null}
                                            placeholder="Search for Location to add..."
                                            noOptionsMessage={({
                                                inputValue,
                                            }) => {
                                                if (inputValue.length > 0) {
                                                    return `No results found for '${inputValue}'.`;
                                                } else {
                                                    return "Enter text to begin searching.";
                                                }
                                            }}
                                            disabled={disabled}
                                        />
                                    </div>
                                </div>
                            </>
                        )}
                    </FieldArray>
                ) : null
            }
        </Field>
    );
};

export default LocationManagement;
