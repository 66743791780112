import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-regular-svg-icons/faCheck";
import { faTimes } from "@fortawesome/pro-regular-svg-icons/faTimes";

import styled from "styled-components";
import startCase from "lodash/startCase";

const TableHeader = styled.h3`
    color: #c8c8c8;
    font-size: 0.8rem;
    padding-bottom: 0.5rem;
    text-transform: uppercase;
    font-weight: 500;
    margin-top: 1rem;
    margin-bottom: 0;
`;

export const UserGroupPermissions = (props) => {
    const { userGroupPermissions } = props;

    console.log("userGroupPermissions", userGroupPermissions);

    const formatPermissions = (data) => {
        const formattedPermissions = [];

        for (const [key, value] of Object.entries(data)) {
            formattedPermissions.push({
                label: startCase(key.replace("ug_", "")),
                value: JSON.stringify(value),
            });
        }

        return formattedPermissions;
    };

    const permissions = formatPermissions(userGroupPermissions.permissions);

    return (
        <>
            <div className="card">
                <div className="card-body p-0">
                    <TableHeader>Group Information</TableHeader>
                    <table className="table table-sm">
                        <colgroup>
                            <col width="30%" />
                            <col width="70%" />
                        </colgroup>
                        <tbody>
                            <tr>
                                <td>Inherits Default Permissions</td>
                                <td data-testid="user-group-inherits-default-permissions">
                                    <FontAwesomeIcon
                                        icon={
                                            userGroupPermissions?.inheritsDefaultPermissions
                                                ? faCheck
                                                : faTimes
                                        }
                                        className={
                                            userGroupPermissions?.inheritsDefaultPermissions
                                                ? "text-success"
                                                : "text-danger"
                                        }
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>Types</td>
                                <td data-testid="user-group-types">
                                    {userGroupPermissions?.types?.length > 0
                                        ? userGroupPermissions.types.join(", ")
                                        : "None"}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="card">
                <div className="card-body p-0">
                    {permissions?.length > 0 && (
                        <>
                            <TableHeader>Permissions</TableHeader>

                            <table className="table table-sm">
                                <colgroup>
                                    <col width="30%" />
                                    <col width="70%" />
                                </colgroup>

                                <tbody>
                                    {permissions.map((permission, idx) => (
                                        <tr key={idx}>
                                            <td
                                                data-testid={`row-${idx}-label`}
                                            >
                                                {permission.label}
                                            </td>
                                            <td
                                                data-testid={`row-${idx}-value`}
                                            >
                                                {permission.value}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </>
                    )}
                </div>
            </div>
        </>
    );
};
