import React from "react";
import { ThemeProvider } from "styled-components";

import Peracto from "@peracto/peracto";
import Config from "@peracto/peracto-config";
import PeractoUser from "@peracto/peracto-user";
import PeractoCatalogue from "@peracto/peracto-catalogue";
import PeractoCommon from "@peracto/peracto-common";
import PeractoContent from "@peracto/peracto-content";
import PeractoLocations from "@peracto/peracto-locations";
import PeractoPromotions from "@peracto/peracto-promotions";

import * as buttonVariants from "./theme/buttonVariants";
import { EditorTheme, AdminTheme, GlobalStyles } from "./theme";

import { colors, fonts } from "./theme/tailwind";

import blocks from "./blocks";
import { menu } from "./menu";

import { UserForm } from "./packages/User";
import { ProductForm, OrdersList, OrdersView } from "./packages/Catalogue";

import compact from "lodash/compact";
import startCase from "lodash/startCase";

const config = new Config({
    api: process.env.REACT_APP_API,
    algolia: {
        applicationId: process.env.REACT_APP_ALGOLIA_APPLICATION_ID,
        searchApiKey: process.env.REACT_APP_ALGOLIA_SEARCH_API_KEY,
        indexName: process.env.REACT_APP_ALGOLIA_INDEX_NAME,
    },
    modules: [
        PeractoUser({ UserForm }),
        PeractoCommon(),
        PeractoCatalogue({ ProductForm, OrdersList, OrdersView }),
        PeractoLocations(),
        PeractoPromotions(),
        PeractoContent(),
    ],
    editorConfig: {
        theme: EditorTheme,
        colorOptions: compact(
            Object.entries(colors).map(([label, value]) => {
                return {
                    label: startCase(label.replaceAll("brand-", "")),
                    value,
                };
            })
        ),
        fonts,
        locales: ["en_GB", "es_ES", "fr_FR"],
        blocks,
        isTailwind: true,
        buttonVariants,
        inputTypes: {},
        stylesheets: [
            "https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap",
        ],
        globalStyles: GlobalStyles,
    },
    features: {
        dashboard: {
            tables: true,
        },
        shippingServices: {
            deliveryDays: true,
            conditions: true,
        },
        products: {
            exportProductData: true,
            productVariants: true,
        },
        user: {
            companySettings: true,
            userGroups: true,
        },
    },
    menu,
});

const App = () => (
    <ThemeProvider theme={AdminTheme}>
        <Peracto config={config} />
    </ThemeProvider>
);

export default App;
